import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'src/components/layouts/default';
import Phone from 'src/assets/icon-phone.png';
import * as classes from './index.module.scss';
import StructuredText from 'src/components/base/structuredText';

export const query = graphql`
  query Homepage {
    datoCmsStart {
      homeTitle
      homeLede {
        blocks
        links
        value
      }
      homeContactUs {
        blocks
        links
        value
      }
      homePhoneHours {
        blocks
        links
        value
      }
      homeFooter {
        blocks
        links
        value
      }
    }
  }
`;

const IndexPage = ({ data, location }) => (
  <Layout location={location} pageTitle={data.datoCmsStart.homeTitle}>
    <div className={classes.body}>
      <div className={classes.ctaTitle}>Ready to enroll?</div>
      <StructuredText data={data.datoCmsStart.homeLede} />
      <div className={classes.cta}>
        <img className={classes.icon} src={Phone} alt="" />
        <h2>Download the app to get started</h2>
        <a href="/enroll" className={'button ' + classes.button}>
          Enroll
        </a>
      </div>
      <div className={classes.secondaryCta}>
        <StructuredText data={data.datoCmsStart.homeContactUs} />
        <StructuredText data={data.datoCmsStart.homePhoneHours} />
      </div>
      <div className={classes.ctaTitle}>Have questions before you get started?</div>
      <div className={classes.cta}>
        <div>
          Give us a call at{' '}
          <a href="tel:866-901-4860">
            <u>866-901-4860</u>
          </a>
        </div>
      </div>
      <footer className={classes.footer}>
        <StructuredText data={data.datoCmsStart.homeFooter} />
      </footer>
    </div>
  </Layout>
);

export default IndexPage;
